<template>
  <h3 class="d-flex align-items-center">
    <i class="bi bi-cloud-arrow-down-fill display-5 me-2"></i> Import
  </h3>
  <div class="row">
    <div class="col-lg-4 mt-3">
      <ImportVoterRaw />
    </div>
    <div class="col-lg-4 mt-3">
      <ImportSupportersOnly />
    </div>
    <div class="col-lg-4 mt-3">
      <ImportBarangays />
    </div>
  </div>
</template>
<script setup>
import ImportBarangays from "@/components/imports/ImportBarangays.vue";
import ImportSupportersOnly from "@/components/imports/ImportSupportersOnly.vue";
import ImportVoterRaw from "@/components/imports/ImportVoterRaw.vue";
import { onMounted } from "vue";

onMounted(() => {});
</script>
