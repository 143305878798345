<template>
  <HeaderNavigation/>
</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';

export default {
  name: 'App',
  components: {
    HeaderNavigation
  }
}
</script>

<style>

</style>