import store from "@/store";
import apiService from "./apiService";

const areaService = {
    async getMunicipalities() {
        try {
            let cities = (await apiService.get(`a/getmunicipalities`, {}, true)).data;
            cities.unshift({ id_mun: 0, name_mun: '' });
            return cities;
        }
        catch {
            return [];
        }
    },
    async getMunicipalitiesWithPolygons() {
        try {
            let cities = (await apiService.get(`a/getmunicipalitieswithpolygon`, {}, true)).data;
            return cities;
        }
        catch {
            return [];
        }
    },
    async getBrgys(id, dontaddPadding) {
        try {
            let brgys = (await apiService.get(`a/getbrgysbycityid/${id}`, {}, true)).data;
            if (!dontaddPadding)
                brgys.unshift({ id_brgy: 0, name_brgy: '' });
            return brgys;
        }
        catch (err) {
            console.error(err);
            return [];
        }
    },
    async getBrgysWithNoPolygon(id) {
        try {
            let brgys = (await apiService.get(`a/getbrgyswnopbycityid/${id}`, {}, true)).data;
            brgys.unshift({ id_brgy: 0, name_brgy: '' });
            return brgys;
        }
        catch (err) {
            console.error(err);
            return [];
        }
    },
    async getBrgysByCityName(city) {
        try {
            let brgys = (await apiService.get(`a/getbrgysbycityname/`, { name: city }, true)).data;
            brgys.unshift({ id_brgy: 0, name_brgy: '' });
            return brgys;
        }
        catch (err) {
            console.error(err);
            return [];
        }
    },
    async getMunicipalAreaVoterSummary(id) {
        try {
            return (await apiService.get(`a/getmunicipalareavotersummary/${id}`, {}, true)).data;
        }
        catch {
            return {};
        }
    },
    async getBrgysWithPolygons() {
        try {
            return (await apiService.get(`a/getbrgysbyboundtouser`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async getBrgyAreaVoterSummary(id) {
        try {
            return (await apiService.get(`a/getbrgyvotersummary/${id}`, {}, true)).data;
        }
        catch (err) {
            console.error(err);
            return {};
        }
    },
    async fetchMarkersFromDatabase(bounds) {
        try {
            return (await apiService.post(`a/gethouseholdsinbound/`, bounds, true)).data;
        }
        catch (err) {
            console.error(err);
            return {};
        }
    },
    async getHouseholdsByCoordinates(coordinates) {
        try {
            return (await apiService.post(`a/gethouseholdsbycoordinates/`, coordinates, true)).data;
        }
        catch {
            return [];
        }
    },
    async getHouseholdContentById(id) {
        try {
            return (await apiService.get(`h/gethouseholdcontentbyid/${id}`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async searchGeoVoter(keyword) {
        try {
            return (await apiService.get(`a/searchgeovoter`, { keyword: keyword }, true)).data;
        }
        catch {
            return [];
        }
    },
    async savePolygonToDatabase(id, type, wkt) {
        try {
            const p = { id_mun: id, area_mun: wkt };
            if (type === "mun") {
                const key = `cities_p${store.getters["auth/currentUser"].syspk_user}`;
                localStorage.removeItem(key);
            }
            return (await apiService.post(`a/savepolygon/${type}`, p, true)).data;
        }
        catch {
            return { success: false, message: "An error occured while processing the request." };
        }
    },
    async searchBrgys(searchModel) {
        try {
            return (await apiService.get(`a/searchbrgysetup`, searchModel, true)).data
        }
        catch {
            return [];
        }
    },
    async searchMunicipalities(searchModel) {
        try {
            return (await apiService.get(`a/searchcitysetup`, searchModel, true)).data;
        }
        catch {
            return [];
        }
    },
    async saveCity(city) {
        try {
            return (await apiService.post(`a/savecity`, city, true)).data;
        }
        catch {
            return { succes: false, message: "An error occured while processing the request." };
        }
    },
    async saveBrgy(brgy) {
        try {
            return (await apiService.post(`a/savebrgy`, brgy, true)).data;
        }
        catch {
            return { succes: false, message: "An error occured while processing the request." };
        }
    },
    async getBrgyForUserRoles() {
        try {
            return (await apiService.get(`a/getallowedbrgys`, {}, true)).data;
        }
        catch {
            return [];
        }
    }

};

export default areaService;