import axios from 'axios';
import { isTokenExpired } from '@/utils/auth';
import { apiConfig } from '@/utils/config';
import { encryptData, decryptData } from '@/utils/crypto';
import signalRService from '@/services/signalRService';
let timer = null; // Timer variable to store the token expiration timer

const state = {
    user: getUserFromLocalStorage(),
    showHeader: true
};
function getUserFromLocalStorage() {
    try {
        return JSON.parse(decryptData(localStorage.getItem('user'))) || null
    }
    catch {
        return null;
    }
}
const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
};

const actions = {
    async login({ commit, dispatch }, credentials) {
        try {
            await dispatch('showSpinner', null, { root: true });

            const response = await axios.post(`${apiConfig.baseUrl}u/submitlogin`, credentials);
            if (response.data.success) {
                const user = response.data.user;

                commit('SET_USER', user);
                localStorage.setItem('user', encryptData(JSON.stringify(user)));

                // Start token expiration timer
                dispatch('startTokenExpirationTimer', user.expiresIn);
                await dispatch('hideSpinner', null, { root: true });
                await signalRService.initializeConnection();
                return user;
            }
            else {
                throw { error: { code: '500', message: response.data.message } }
            }
        } catch (error) {
            throw error.response ? error.response.data : error;
        } finally {
            await dispatch('hideSpinner', null, { root: true });
        }
    },
    async activate({ commit, dispatch }, code) {
        try {
            const response = await axios.get(`${apiConfig.baseUrl}v1/u/activate/${code}`);
            if (response.data.success) {
                const user = response.data.user;

                commit('SET_USER', user);
                localStorage.setItem('user', encryptData(JSON.stringify(user)));

                // Start token expiration timer
                dispatch('startTokenExpirationTimer', user.expiresIn);
                return user;
            }
            else {
                throw { error: { code: '', message: response.data.message } }
            }
        } catch (error) {
            throw error.response ? error.response.data : error;
        }
    },
    async logout({ commit,dispatch }) {
        dispatch('showAdminHeader', false, { root: true });
        commit('SET_USER', null);

        if (signalRService.connection) {
            try {
                await signalRService.connection.stop();
                console.log('SignalR disconnected');
            } catch (err) {
                console.error('SignalR disconnection error:', err);
            }
        }
        Object.keys(localStorage).forEach(key => {
            //if (key !== 'user') {
            localStorage.removeItem(key);
            //}
        });
        clearInterval(timer);
        timer = null;
    },
    async initializeStore({ commit, dispatch }) {
        const user = JSON.parse(decryptData(localStorage.getItem('user')));
        if (user) {
            if (isTokenExpired(user.expiresIn)) {
                // Token has expired, attempt to refresh token
                try {
                    const refreshedUser = await dispatch('refreshToken');
                    commit('SET_USER', refreshedUser);
                    dispatch('startTokenExpirationTimer', refreshedUser.expiresIn);

                } catch (error) {
                    // Handle refresh token failure (e.g., log out user)
                    console.error('Failed to refresh token:', error);
                    commit('SET_USER', null);
                    localStorage.removeItem('user');
                }
            } else {
                // Token is still valid, start token expiration timer
                dispatch('startTokenExpirationTimer', user.expiresIn);
                commit('SET_USER', user);
            }
            signalRService.initializeConnection();
        }
    },
    startTokenExpirationTimer({ dispatch }, expiresIn) {
        // Clear existing timer if it exists
        if (timer) {
            clearInterval(timer);
            timer = null;
        }

        // Calculate token expiration time
        const expirationTime = expiresIn * 1000; // Convert to milliseconds
        const now = Date.now();
        const timeout = expirationTime - now;

        // Set timer to refresh token 5 minutes before expiration
        const refreshTime = Math.max(5 * 60 * 1000, timeout - (5 * 60 * 1000)); // 5 minutes in milliseconds
        timer = setTimeout(async () => {
            console.log('Token refresh initiated...');
            try {
                const refreshedUser = await dispatch('refreshToken');
                dispatch('startTokenExpirationTimer', refreshedUser.expiresIn);
                console.log('token refreshed successfully!');
            } catch (error) {
                console.error('Failed to refresh token:', error);
                dispatch('logout');
            }
        }, refreshTime);
    },
    async refreshToken({ commit, state }) {
        try {
            // Make a GET request to refresh the token
            const response = await axios.get(
                `${apiConfig.baseUrl}v1/u/refreshtoken`,
                {
                    headers: {
                        Authorization: `Bearer ${state.user.refreshToken}` // Include refresh token as bearer token
                    }
                }
            );

            // If successful, update user state and local storage with refreshed user data
            const refreshedUser = response.data.user;
            commit('SET_USER', refreshedUser);
            localStorage.setItem('user', encryptData(JSON.stringify(refreshedUser)));

            return refreshedUser; // Return refreshed user data if needed
        } catch (error) {
            // Handle error responses, including network errors or invalid token errors
            throw error.response ? error.response.data : error;
        }
    },

};

const getters = {
    isAuthenticated: state => !!state.user && !isTokenExpired(state.user.expiresIn),
    currentUser: state => state.user,
    userRoles: state => state.user ? state.user.roles : [],
    showHeader: state => state.showHeader
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
