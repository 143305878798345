<template>
  <div class="row">
    <!-- Card for Total Number of People -->
    <div class="col-md-4 mb-4">
      <div class="card border-primary shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-person-fill fs-2 text-primary me-3"></i>
            <div class="w-100">
              <h5 class="card-title">Total Expected Voters</h5>
              <p
                v-show="
                  voterSummarry.totalSupporters + voterSummarry.totalNonSupporters > 0
                "
                class="card-text fs-4 mb-0 d-flex justify-content-between align-items-center"
              >
                <span>
                  <NumberCounter
                    :startValue="startValue"
                    :endValue="
                      voterSummarry.totalSupporters + voterSummarry.totalNonSupporters
                    "
                    :duration="0.5"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Total Number of Supporters -->
    <div class="col-md-4 mb-4">
      <div class="card border-success shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-heart-fill fs-2 text-success me-3"></i>
            <div>
              <h5 class="card-title">Total Supporters</h5>
              <p class="card-text fs-4 mb-0" v-show="voterSummarry.totalSupporters > 0">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="voterSummarry.totalSupporters"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 1 -->
    <div class="col-md-4 mb-4">
      <div class="card border-danger shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-calendar-check fs-2 text-danger me-3"></i>
            <div>
              <h5 class="card-title">Total Non-Supporters</h5>
              <p class="card-text fs-4 mb-0">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="voterSummarry.totalNonSupporters"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 2 -->
    <div class="col-md-4 mb-4">
      <div class="card border-success shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-check-all fs-2 text-success me-3"></i>
            <div>
              <h5 class="card-title">Completed</h5>
              <p class="card-text fs-4 mb-0">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.completed"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card for Additional Metric 3 -->
    <div class="col-md-4 mb-4">
      <div class="card border-info shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-check fs-2 text-info me-3"></i>
            <div>
              <h5 class="card-title">Partial</h5>
              <p class="card-text fs-4 mb-0">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.partially"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div class="card border-secondary shadow-sm">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <i class="bi bi-hourglass-top fs-2 text-secondary me-3"></i>
            <div>
              <h5 class="card-title">Pending</h5>
              <p class="card-text fs-4 mb-0">
                <NumberCounter
                  :startValue="startValue"
                  :endValue="scanningSummary.pending"
                  :duration="0.5"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardService from "@/services/dashboardService";
import signalRService from "@/services/signalRService";
import { onMounted, watch, onUnmounted, ref } from "vue";
import NumberCounter from "../customs/NumberCounter.vue";
import { debounce } from "@/utils/debounce";
export default {
  components: {
    NumberCounter,
  },
  props: {
    id: { type: Number, default: () => 0 },
  },
  setup(props) {
    const voterSummarry = ref({
      totalSupporters: 0,
      totalNonSupporters: 0,
      totalRawVoters: 0,
      expectedTurnout: 0.0,
    });
    const startValue = ref(0);
    const endValue = ref(1000);

    const updateValues = () => {
      // Example of changing values dynamically
      startValue.value = endValue.value;
      endValue.value = Math.floor(Math.random() * 2000);
    };
    const scanningSummary = ref({
      pending: 0,
      partially: 0,
      completed: 0,
    });
    const getVoterSummary = async () => {
      voterSummarry.value = await dashboardService.getVoterSummary(props.id);
    };
    const getScanSummary = async () => {
      scanningSummary.value = await dashboardService.getScanningSummary(props.id);
    };
    // Create a debounced version of fetchData
    const debouncedGetVoterSummary = debounce(getVoterSummary, 300); // Adjust debounce delay as needed
    const debouncedGetScanSummary = debounce(getScanSummary, 300); // Adjust debounce delay as needed
    onMounted(() => {
      signalRService.subscribeToEvent("VoterSideUpdated", debouncedGetVoterSummary);
      signalRService.subscribeToEvent("VoterScanned", debouncedGetScanSummary);
      signalRService.subscribeToEvent("TEST", (msg) => {
        alert(msg);
      });
      // getVoterSummary();
      // getScanSummary();
    });

    watch(
      () => props.id,
      () => {
        getVoterSummary();
        getScanSummary();
      }
    );

    onUnmounted(() => {
      signalRService.connection.off("VoterSideUpdated", debouncedGetVoterSummary);
      signalRService.connection.off("VoterScanned", debouncedGetScanSummary);
      signalRService.connection.off("TEST");
    });

    return {
      voterSummarry,
      scanningSummary,
      startValue,
      endValue,
      updateValues,
    };
  },
};
</script>

<style scoped>
.card {
  border-radius: 0.75rem;
}
.card-body {
  display: flex;
  align-items: center;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.turnout-percentage {
  margin-left: auto;
  text-align: right; /* Optional: Align text to the right if needed */
}
</style>
