<template>
  <div v-if="!isProcessing">
    <div v-if="contentAction === 'showInfo'" class="container mt-3">
      <img :src="imgUrl" class="img-fluid" alt="Banner Image" />
      <h2 class="text-center">
        {{ title }}
        <span class="badge bg-primary badge-exponent text-light rounded-circle">{{
          persons.length
        }}</span>
      </h2>
      <div class="row">
        <div
          v-for="person in persons"
          :key="person.syspk_voter"
          class="col-md-6 col-lg-6 mb-3"
        >
          <div class="card" :class="{ 'bg-success': person.syspk_voter === personId }">
            <div class="card-body">
              <h5 class="card-title">
                <i class="bi bi-person"></i> {{ person.name_voter }}
              </h5>
              <p class="card-text">
                <i class="bi bi-calendar3"></i> <strong>Gender:</strong>
                {{ person.gender_voter }}
              </p>
              <p class="card-text">
                <i class="bi bi-briefcase"></i> <strong>Type:</strong>
                {{ person.type_voter }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HouseholdInMarker
      v-else
      :households="households"
      @household-selected="onHouseholdSelected"
    />
  </div>
  <div v-else class="text-center">
    <div class="spinner-container">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import HouseholdInMarker from "./HouseholdInMarker.vue";
import areaService from "@/services/areaService";

export default {
  components: {
    HouseholdInMarker,
  },
  props: {
    id: String,
    personId: { type: Number, default: () => 0 },
    coordinates: { type: Object, default: () => null },
  },
  setup(props) {
    const contentAction = ref("showOptions");
    const isProcessing = ref(false);
    const households = ref([]);
    const persons = ref([]);
    const title = ref("");
    const imgUrl = ref("/news-1.jpg");
    /*  const getInfo = async (id) => {
          if (id === null) return;
        }; */
    const onHouseholdSelected = async (household) => {
      isProcessing.value = true;
      contentAction.value = "showInfo";
      title.value = household.name_hh.toUpperCase();
      persons.value = await areaService.getHouseholdContentById(household.syspk_hh);
      isProcessing.value = false;
    };
    const getHouseholdsByCoordinates = async (coords) => {
      contentAction.value = "showOptions";
      isProcessing.value = true;
      const response = await areaService.getHouseholdsByCoordinates(coords);
      households.value = response;
      isProcessing.value = false;
    };
    onMounted(() => {
      if (props.coordinates !== null) getHouseholdsByCoordinates(props.coordinates);
    });
    watch(
      () => props.coordinates,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          //getInfo(newVal);
          getHouseholdsByCoordinates(newVal);
        }
      }
    );
    return {
      persons,
      contentAction,
      households,
      onHouseholdSelected,
      isProcessing,
      title,
      imgUrl,
    };
  },
};
</script>
<style scoped>
.card-title i,
.card-text i {
  margin-right: 5px;
}

.badge-exponent {
  position: relative;
  top: -0.4em; /* Adjust the value as needed for proper alignment */
  font-size: 0.8em; /* Adjust the font size as needed */
}

@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
