<template>
  <select @change="handleChange" class="form-select my-2" v-model="currentCityId">
    <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
      {{ city.name_mun }}
    </option>
  </select>
  <div class="row pt-2">
    <div class="col-lg-9">
      <DashboardCards :id="currentCityId" />
      <DashboardBrgysTable :id="currentCityId" />
    </div>
    <div class="col-lg-3">
      <DashboardOnlineUsers
        :id="currentCityId"
        :totalOnline="onlineUserCount"
        :previewUsers="previewUsers"
        @user-clicked="onUserClicked"
      />
      <DashboardRecentLogs :id="currentCityId" />
    </div>
  </div>

  <MediumSizedModal :title="modalTitle" :show="showModal" @close="toggleModal">
    <div class="card">
      <div class="card-body d-flex justify-content-between align-items-center">
        <div>
          <h5 class="card-title mb-1">{{ currentusername }}</h5>
          <p class="card-text mb-0">
            Last seen: {{ formatRelativeTime(currentUserLocation.timestamp_loc) }}
          </p>
        </div>
        <button class="btn btn-primary btn-locate" @click.prevent="locateUser">
          <i class="bi bi-geo-alt-fill"></i> Locate
        </button>
      </div>
    </div>
  </MediumSizedModal>
</template>
<script>
import DashboardBrgysTable from "@/components/dashboard/DashboardBrgysTable.vue";
import DashboardCards from "@/components/dashboard/DashboardCards.vue";
import DashboardOnlineUsers from "@/components/dashboard/DashboardOnlineUsers.vue";
import DashboardRecentLogs from "@/components/dashboard/DashboardRecentLogs.vue";
import MediumSizedModal from "@/components/modals/MediumSizedModal.vue";
import areaService from "@/services/areaService";
import signalRService from "@/services/signalRService";
import userService from "@/services/userService";
import { formatDistanceToNow } from "date-fns";
import { onMounted, onUnmounted, ref } from "vue";
import { getUserRoles } from "@/utils/auth";
export default {
  components: {
    DashboardCards,
    DashboardOnlineUsers,
    DashboardRecentLogs,
    DashboardBrgysTable,
    MediumSizedModal,
  },
  setup() {
    const onlineUserCount = ref(0);
    const previewUsers = ref([]);
    const currentCityId = ref(0);
    const cities = ref([]);
    const showModal = ref(false);

    const currentusername = ref("");
    const currentUserLocation = ref({ syspk_loc: 0, timestamp_loc: new Date() });
    const isGettingLocation = ref(false);
    const modalTitle = ref("User Location");
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };
    const handleChange = () => {
      localStorage.setItem("user-defaultcity", currentCityId.value);
    };
    const formatRelativeTime = (timestamp) => {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };

    onMounted(async () => {
      signalRService.subscribeToEvent("UserCountUpdated", handleMessage);
      cities.value = await areaService.getMunicipalities();
      const citystorage = localStorage.getItem("user-defaultcity");
      if (citystorage) {
        currentCityId.value = Number(citystorage);
      }
      fetchUsers(currentCityId.value);
    });
    const handleMessage = async (userCount) => {
      onlineUserCount.value = userCount;
      await fetchUsers(currentCityId.value);
    };
    const fetchUsers = async (id) => {
      try {
        const response = await userService.getPaginatedUsers(id, 1, 5);
        previewUsers.value = response.users;
        onlineUserCount.value = response.totalCount;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const onUserClicked = async (user) => {
      modalTitle.value = `User Location`;
      currentusername.value = user.userName;
      const requiredRoles = ["admin", "locate-user"];
      if (getUserRoles().some((role) => requiredRoles.includes(role))) {
        toggleModal();
        currentUserLocation.value = await userService.getLastKnownLocation(user.userId);
      } else {
        return;
      }
    };
    const locateUser = () => {
      const url = `https://www.google.com/maps?q=${currentUserLocation.value.lat_loc},${currentUserLocation.value.lng_loc}`;
      // Open the URL in a new tab
      window.open(url, "_blank");
    };
    // Clean up subscriptions when component unmounts
    onUnmounted(() => {
      signalRService.connection.off("ReceiveMessage", handleMessage);
    });

    return {
      onlineUserCount,
      previewUsers,
      currentCityId,
      onUserClicked,
      modalTitle,
      toggleModal,
      showModal,
      isGettingLocation,
      locateUser,
      formatRelativeTime,
      currentUserLocation,
      currentusername,
      cities,
      handleChange,
    };
  },
};
</script>
