<template>
  <nav v-show="totalItems > 0" aria-label="Pagination">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button
          type="button"
          class="page-link"
          @click="onPageChange(1)"
          :disabled="currentPage === 1"
        >
          <i class="bi bi-chevron-double-left"></i> First
        </button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button
          type="button"
          class="page-link"
          @click="onPageChange(currentPage - 1)"
          :disabled="currentPage === 1"
        >
          <i class="bi bi-chevron-left"></i> Prev
        </button>
      </li>
      <li class="page-item">
        <span class="page-link">{{ currentPage }}</span>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <button
          type="button"
          class="page-link"
          @click="onPageChange(currentPage + 1)"
          :disabled="currentPage === totalPages"
        >
          Next <i class="bi bi-chevron-right"></i>
        </button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <button
          type="button"
          class="page-link"
          @click="onPageChange(totalPages)"
          :disabled="currentPage === totalPages"
        >
          Last <i class="bi bi-chevron-double-right"></i>
        </button>
      </li>
    </ul>
    <p class="text-center mt-2">
      Showing page {{ currentPage }} of {{ totalPages }} (Total {{ totalItems }} items)
    </p>
  </nav>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    page: { type: Number },
    pages: { type: Number },
    items: { type: Number },
  },
  setup(props, { emit }) {
    const currentPage = computed(() => props.page);
    const totalPages = computed(() => props.pages);
    const totalItems = computed(() => props.items);
    const onPageChange = (page) => {
      emit("page-change", page);
    };

    return {
      onPageChange,
      currentPage,
      totalItems,
      totalPages,
    };
  },
};
</script>

<style scoped>
/* Optional: Customize pagination styles */
.page-item {
  cursor: pointer;
}
.page-link {
  color: #007bff;
}
.page-link:hover {
  text-decoration: none;
  background-color: #f8f9fa;
  border-color: #dee2e6;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #dee2e6;
}
</style>
